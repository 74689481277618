.listItemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    border-bottom: 1px solid #d3d3d34d;
    padding: 6px 12px;
}

.userProfileContainer {
    width: 35px;
    aspect-ratio: 1/1;
    border-radius: 25px;
    background-color: lightblue;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.name {
    font-size: 12px;
}

.desc {
    font-size: 12px;
}
.StationList{
    display: flex;
    justify-content: space-between;
    width: 100%;
}