.root {
    // padding: 6rem 1rem 0rem 1rem;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - (6rem + 43px));
    padding: 12px 0px;
    justify-content: space-evenly;
    align-items: center;
}

.title {
    font-size: 24px;
    font-weight: bolder;
    color: var(--primary);
}

.optionRoot {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: baseline;
}

.optionItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 24px;
    width: 100%;
}

.navigateBtn {
    cursor: pointer;
    background-color: var(--primary);
    height: 60px;
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;

    p {
        color: white;
    }
}

.description {
    text-align: center;
    font-size: 14px;
}


/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767px) {
    .container {
        row-gap: 24px;
    }

    .optionRoot {
        flex-direction: column;
        row-gap: 24px;
    }

    .navigateBtn {
        width: 50%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}