.container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - (6rem + 43px));
    padding: 12px 0px;
    justify-content: space-evenly;
    align-items: center;
}

.title {
    font-size: 24px;
    font-weight: bolder;
    color: var(--primary);
}

.iconContainer {
    height: 150px;

    img {
        height: 100%;
    }
}

.inputSearch {
    width: 80%;
}