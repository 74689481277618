body {
  margin: 0;
}

:root {
  --red: #dc3545;
  --white: #fff;
  --grey: #adb5bd;
  --green: #198754;
  --teal: #20c997;
  --blue: #0d6efd;
  --violet: #B15AD2;
  --primary: #4696DE;
  --darkBlue: #191F71;
  --gradientPrimary: radial-gradient(circle at 10% 20%, rgb(239, 246, 249) 0%, rgb(206, 239, 253) 90%);
}


/* Start Sidebar Css */

.sidebar {
  width: 100%;
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.sidebar-item {
  padding: 3px 10px;
  display: block;
  transition: background-color .15s;
}

.sidebar-item:hover:not(.active) {
  background-color: rgba(211, 211, 211, 0.603);
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
}

.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
  color: #666666;
}

.sidebar-title span span {
  color: #191F71;
}

.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform .3s;
}

.sidebar-item.open>.sidebar-title .toggle-btn {
  transform: rotate(180deg);
}

.sidebar-content {
  padding-top: .25em;
  height: 0;
  overflow: hidden;
}

.sidebar-item.open>.sidebar-content {
  height: auto;
}

.sidebar-item.plain:not(.active) {
  color: #000000;
  text-decoration: none;
}

.sidebar-item.plain i:not(.active) {
  display: inline-block;
  width: 1.7em;
  color: #666666 !important;
}


.active {
  color: #000000 !important;
  background-color: #fff !important;
  text-decoration: none !important;

}

.active {
  border-left: 5px solid #4696de;
}

/* End Sidebar Css */

/* Start Input Css */

.p-dropdown {
  height: 30px !important;

}

.p-inputtext {
  font-size: 12px !important;
  padding: 4px;
}

.p-calendar {
  height: 30px !important;
}

.p-multiselect {
  height: 30px !important;
}

.p-multiselect .p-multiselect-label {
  padding: 4px !important;
  font-size: 14px !important;
}

/* End Input Css */