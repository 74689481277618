.breadCrumb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    padding: 5px 5px;
}

.customBtn {
    width: auto;
    height: 30px;

    span {
        font-size: 12px !important;
    }
}

.invertBtn {
    background-color: transparent;
    border: transparent;
    color: black;
    box-shadow: none;
}