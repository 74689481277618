.customStyle {
    // padding: 12px 12px;
    border-radius: 4px;
    height: 42px;
    border-radius: 5px;
    width: 200px;
    font-weight: 400;
    height: 30px !important;

    span {
        font-weight: 400 !important;
        font-size: 14px;
    }
}

.primary {
    background-color: var(--primary);
    border: 1px solid var(--primary)
}

.primary:hover {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #83d3f8, 0 1px 2px 0 rgb(0, 0, 0);
}

.success {
    background: #22c55e;
    border: 1px solid #22c55e
}

.success:hover {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #88eaac, 0 1px 2px 0 rgb(0, 0, 0);
}

.danger {
    background: #ef4444;
    border: 1px solid #ef4444;
}

.danger:hover {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f7a2a2, 0 1px 2px 0 rgb(0, 0, 0);
}

.secondary {
    background: #64748b;
    border: 1px solid #64748b;

    &:hover {
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b0b9c6, 0 1px 2px 0 rgb(0, 0, 0);
    }
}

.invertBtn {
    background-color: transparent;
    border: transparent;
    color: black;
    box-shadow: none;
}