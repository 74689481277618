.cardRoot {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    // padding: 0px 12px;
    position: relative;
    // height: 100%;
    // height: 40vh;
}

.listContainer {
    max-height: 300px;
    overflow-y: auto;
    // height: 100%;
}

.listContainer::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}

.listContainer::-webkit-scrollbar-track {
    background-color: rgb(240, 231, 231);
}

.listContainer::-webkit-scrollbar-thumb {
    background-color: rgb(109, 109, 109, 0.5);
    border-radius: 10px;
}

.cardFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-top: 1px solid #d3d3d34d;
    position: absolute;
    background-color: #ffff;
    bottom: 0px;
    width: 100%;

    p {
        font-size: 10px;
        color: grey;
    }
}