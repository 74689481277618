.root {
    padding: 12px 0px;
}

.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
    gap: 0.5rem;
    margin: 4px 0px;
}

.subContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
}

.cardItem {
    border: 1px solid lightcoral;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767px) {
    .container {
        grid-template-columns: 1fr;
    }

    .subContainer {
        grid-template-columns: 1fr;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
    .container {
        grid-template-columns: 1fr;
    }

    .subContainer {
        grid-template-columns: 1fr;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}