.headerRoot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #d3d3d34d
}

.iconContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;

    i {
        font-size: 14px;
        color: var(--grey);
        cursor: pointer;
    }
}

.headerTitle {
    font-weight: 700;
    font-size: 12px;
}

.helperText {
    font-size: 12px;
}